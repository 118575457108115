import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Slideshow from '../components/Slideshow';
import Content, { HTMLContent } from '../components/Content';

export const GalleryPageTemplate = ({ content, contentComponent, title, tags, gallery}) => {

  const PageContent = contentComponent || Content

  return (
    <section className="section">
      <div className="container is-max-widescreen">

        <h1 className="title is-size-4 has-text-centered grey-text is-lowercase">
          {title}
        </h1>

        {Content ? (
          <div class="columns is-centered is-vcentered">
            <div class="column has-text-centered">
              <PageContent className="content" content={content} />
            </div>
          </div>
        ) : null}

        {gallery ? (
          <div className="columns is-centered">
            <div className="column is-10 slideshow-container">
                <Slideshow items={gallery.items} />
            </div>
          </div>
        ) : null}

        <div>
          {tags && tags.length ? (
            <div style={{ marginTop: `2rem` }}>
              <ul className="taglist">
                {tags.map((tag) => (
                  <li key={tag + `tag`}>
                    <Link className="button is-small" to={`/artworks/${kebabCase(tag)}/`}>{tag}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>

      </div>
    </section>
  )
}






GalleryPageTemplate.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helmet: PropTypes.object,
  gallery: PropTypes.shape({
    items: PropTypes.array,
  }),
}

const GalleryPage = ({ data }) => {
  const { markdownRemark: gal } = data

  return (
    <Layout>
      <GalleryPageTemplate
        content={gal.html}
        contentComponent={HTMLContent}
        image={gal.frontmatter.image}
        title={gal.frontmatter.title}
        description={gal.frontmatter.description}
        gallery={gal.frontmatter.gallery}
        helmet={
          <Helmet titleTemplate="%s | Artworks">
            <title>{`${gal.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${gal.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={gal.frontmatter.tags}
      />
    </Layout>
  )
}

GalleryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default GalleryPage

export const pageQuery = graphql`
  query GalleryPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        gallery {
          items {
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  height: 800
                  formats: [AUTO,WEBP]
                )
              }
            }
            text
          }
        }
        tags
      }
    }
  }
`

// query GalleryPageTemplate($id: String!) {
//   markdownRemark(id: { eq: $id }) {
//     id
//     html
//     frontmatter {
//       title
//       description
//       gallery {
//         items {
//           image {
//             childImageSharp {
//               gatsbyImageData(layout: FIXED)
//               fluid(maxHeight: 800, quality: 70) {
//                 ...GatsbyImageSharpFluid_withWebp_noBase64
//                 ...GatsbyImageSharpFluidLimitPresentationSize
//               }
//             }
//           }
//           text
//         }
//       }
//       tags
//     }
//   }
// }